(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-jackpot-blocks", ["React", "mobx", "mobxReact", "sbJsExtends", "sbRespBlockLib", "sbInternalMsgBus", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-jackpot-blocks"] = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("ReactDOM"));
	else
		root["sb-responsive-jackpot-blocks"] = factory(root["React"], root["mobx"], root["mobxReact"], root["sbJsExtends"], root["sbRespBlockLib"], root["sbInternalMsgBus"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__16__) {
return 